exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-board-of-directors-index-tsx": () => import("./../../../src/pages/about/board-of-directors/index.tsx" /* webpackChunkName: "component---src-pages-about-board-of-directors-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-organization-chart-index-tsx": () => import("./../../../src/pages/about/organization-chart/index.tsx" /* webpackChunkName: "component---src-pages-about-organization-chart-index-tsx" */),
  "component---src-pages-businesses-tonghua-asset-index-tsx": () => import("./../../../src/pages/businesses/tonghua-asset/index.tsx" /* webpackChunkName: "component---src-pages-businesses-tonghua-asset-index-tsx" */),
  "component---src-pages-businesses-tonghua-asset-management-index-tsx": () => import("./../../../src/pages/businesses/tonghua-asset-management/index.tsx" /* webpackChunkName: "component---src-pages-businesses-tonghua-asset-management-index-tsx" */),
  "component---src-pages-businesses-tonghua-capital-index-tsx": () => import("./../../../src/pages/businesses/tonghua-capital/index.tsx" /* webpackChunkName: "component---src-pages-businesses-tonghua-capital-index-tsx" */),
  "component---src-pages-businesses-tonghua-media-labs-index-tsx": () => import("./../../../src/pages/businesses/tonghua-media-labs/index.tsx" /* webpackChunkName: "component---src-pages-businesses-tonghua-media-labs-index-tsx" */),
  "component---src-pages-career-index-tsx": () => import("./../../../src/pages/career/index.tsx" /* webpackChunkName: "component---src-pages-career-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-index-tsx": () => import("./../../../src/pages/investor/index.tsx" /* webpackChunkName: "component---src-pages-investor-index-tsx" */),
  "component---src-pages-newsroom-all-document-index-tsx": () => import("./../../../src/pages/newsroom/all-document/index.tsx" /* webpackChunkName: "component---src-pages-newsroom-all-document-index-tsx" */),
  "component---src-pages-newsroom-all-set-announcement-index-tsx": () => import("./../../../src/pages/newsroom/all-set-announcement/index.tsx" /* webpackChunkName: "component---src-pages-newsroom-all-set-announcement-index-tsx" */),
  "component---src-pages-newsroom-index-tsx": () => import("./../../../src/pages/newsroom/index.tsx" /* webpackChunkName: "component---src-pages-newsroom-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-templates-all-news-template-tsx": () => import("./../../../src/templates/AllNewsTemplate.tsx" /* webpackChunkName: "component---src-templates-all-news-template-tsx" */),
  "component---src-templates-markdown-template-tsx": () => import("./../../../src/templates/MarkdownTemplate.tsx" /* webpackChunkName: "component---src-templates-markdown-template-tsx" */)
}

